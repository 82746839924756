@import "~theme/variables";

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: $space-xl;
}

.clubPage {
  padding: $space-s 0;
}